const colors = {
  white: "#fff",
  background: "#080808",
  textColor: "#3D3D3D",
  primaryColor: "#F5C518",
  servicesTitle: "#000000",
  servicesSubtitle: "#3D3D3D",
};

const Typography = {
  color: colors,
  mainHeadingH1: {
    color: colors.background,
    fontSize: 40,
    fontWeight: "bold",
  },
  mainHeadingH2: {
    color: colors.textColor,
    fontSize: 28,
    fontWeight: "normal",
  },
  button: {
    secondaryLinear: "linear-gradient(to right, #F5C518), #F5C518)",
  },
};
export default Typography;
